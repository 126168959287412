import React, { useState } from 'react'
import { useBox } from '@react-three/cannon'
const sounds = [
	document.getElementById('sound1'),
	document.getElementById('sound2'),
	document.getElementById('sound3'),
	document.getElementById('sound4')
];
var currentNum = 0;
let colorname = "black"
var sequence = [];
var audio1 = new Audio('assets/sounds/simonSound1.mp3');
var audio2 = new Audio('assets/sounds/simonSound2.mp3');
var audio3 = new Audio('assets/sounds/simonSound3.mp3');
var audio4 = new Audio('assets/sounds/simonSound4.mp3');
let playingLoss = false;
function Simon() {
	function Test(props) {
		const [refRed] = useBox(() => ({ args: [2, 2, 2], mass: 1, position: [-6, 1, -15], rotation: [0, 0, 0], ...props, type: 'Static' }));
		const [refYellow] = useBox(() => ({ args: [2, 2, 2], mass: 1, position: [-2, 1, -15], rotation: [0, 0, 0], ...props, type: 'Static' }));
		const [refGreen] = useBox(() => ({ args: [2, 2, 2], mass: 1, position: [2, 1, -15], rotation: [0, 0, 0], ...props, type: 'Static' }));
		const [refBlue] = useBox(() => ({ args: [2, 2, 2], mass: 1, position: [6, 1, -15], rotation: [0, 0, 0], ...props, type: 'Static' }));
		const [[active1, active2, active3, active4], setActive] = useState([false, false, false, false]);
		function SequenceAdd() {
			let i = 0;
			let randomNum = Math.floor(Math.random() * 4) + 1;
			sequence.push(randomNum);
			const id = setInterval(() => {
				Blink(sequence[i++]);
				if (i === sequence.length) {
					clearInterval(id);
				}
			}, 1000);
		}
		function Blink(num) {
			setActive([false, false, false, false]);
			switch (num) {
				case 1:
					setActive([true, active2, active3, active4]);
					audio1.play()
					break;
				case 2:
					setActive([active1, true, active3, active4]);
					audio2.play()
					break;
				case 3:
					setActive([active1, active2, true, active4]);
					audio3.play()
					break;
				case 4:
					setActive([active1, active2, active3, true]);
					audio4.play()
					break;
			}
			let loop2 = setTimeout(() => {
				switch (num) {
					case 1:
						setActive([false, active2, active3, active4]);
						break;
					case 2:
						setActive([active1, false, active3, active4]);
						break;
					case 3:
						setActive([active1, active2, false, active4]);
						break;
					case 4:
						setActive([active1, active2, active3, false]);
						break;
				}
			}, 500);
		}		
		function BlinkFast(num) {
			var audio = new Audio('assets/sounds/simonSound1.mp3');
			switch (num) {
				case 1:
					setActive([true, active2, active3, active4]);
					audio1.pause();
					audio1.currentTime = 0;
					audio1.play()
					break;
				case 2:
					setActive([active1, true, active3, active4]);
					audio2.pause();
					audio2.currentTime = 0;
					audio2.play()
					break;
				case 3:
					setActive([active1, active2, true, active4]);
					audio3.pause();
					audio3.currentTime = 0;
					audio3.play()
					break;
				case 4:
					setActive([active1, active2, active3, true]);
					audio4.pause();
					audio4.currentTime = 0;
					audio4.play()
					break;
			}
			let loop2 = setTimeout(() => {
				switch (num) {
					case 1:
						setActive([false, active2, active3, active4]);
						break;
					case 2:
						setActive([active1, false, active3, active4]);
						break;
					case 3:
						setActive([active1, active2, false, active4]);
						break;
					case 4:
						setActive([active1, active2, active3, false]);
						break;
				}
			}, 200);
		}	
		function Reset(){
			LossAnimation();
			currentNum = 0;
			sequence = [];
			setActive([false, false, false, false]);
		}	
		function LossAnimation(num) {
			if(!playingLoss){
				playingLoss = true;
				let loop = setTimeout(() => {
					setActive([true, true, true, true]);
				}, 100);
				let loop1 = setTimeout(() => {
					setActive([false, false, false, false]);
				}, 200);
				let loop2 = setTimeout(() => {
					setActive([true, true, true, true]);
				}, 400);
				let loop3 = setTimeout(() => {
					setActive([false, false, false, false]);
					playingLoss = false;
					SequenceAdd();
				}, 600);
			}
		}
		function Check(num) {
			BlinkFast(num);
			if (num == sequence[currentNum]) {
				currentNum++;
				if (currentNum == sequence.length) {
					currentNum = 0;
					SequenceAdd();
				}
			}
			else {
				Reset();
			}
		}
		return (
			<>
				<mesh ref={refRed} receiveShadow castShadow {...props}
					onClick={() => {
						Check(1);
					}} 
        			onPointerOver={(event) => document.body.style.cursor = 'pointer'}
        			onPointerOut={(event) => document.body.style.cursor = 'auto'} >
					<boxGeometry args={[2, 2, 2]} />
					<meshStandardMaterial color={active1 ? colorname : "red"} />
				</mesh>
				<mesh ref={refYellow} receiveShadow castShadow {...props}
					onClick={() => {
						Check(2);
					}}
        			onPointerOver={(event) => document.body.style.cursor = 'pointer'}
        			onPointerOut={(event) => document.body.style.cursor = 'auto'} >
					<boxGeometry args={[2, 2, 2]} />
					<meshStandardMaterial color={active2 ? colorname : "yellow"} />
				</mesh>
				<mesh ref={refGreen} receiveShadow castShadow {...props}
					onClick={() => {
						Check(3);
					}} 
        			onPointerOver={(event) => document.body.style.cursor = 'pointer'}
        			onPointerOut={(event) => document.body.style.cursor = 'auto'}>
					<boxGeometry args={[2, 2, 2]} />
					<meshStandardMaterial color={active3 ? colorname : "green"} />
				</mesh>
				<mesh ref={refBlue} receiveShadow castShadow {...props}
					onClick={() => {
						Check(4);
					}} 
        			onPointerOver={(event) => document.body.style.cursor = 'pointer'}
        			onPointerOut={(event) => document.body.style.cursor = 'auto'}>
					<boxGeometry args={[2, 2, 2]} />
					<meshStandardMaterial color={active4 ? colorname : "blue"} />
				</mesh>
				{/* <mesh receiveShadow castShadow position={[0, 1, -5]}
					onClick={() => {
						SequenceAdd();
					}} >
					<boxGeometry args={[2, 2, 2]} />
					<meshStandardMaterial color={"purple"} />
				</mesh> */}
			</>
		)
	}
	return (
		<>
			<Test />
			{/* <Cube position={[-6, 1, -10]} num={1} color="red" /> */}
			{/* <Cube position={[-2, 1, -10]} num={2} color="yellow" /> */}
			{/* <Cube position={[2, 1, -10]} num={3} color="green" />
			<Cube position={[6, 1, -10]} num={4} color="blue" /> */}
			{/* <Start position={[0, 1, -5]} color="purple" /> */}
		</>

	)


}

export default Simon;
