import ReactDOM from 'react-dom'
import { extend } from '@react-three/fiber'
import React, { useRef, Suspense, useState} from 'react'
import { Canvas, useLoader, useFrame} from '@react-three/fiber'
import { Physics, usePlane, useBox, Debug, useContactMaterial } from '@react-three/cannon'
import { TextureLoader } from 'three/src/loaders/TextureLoader.js'
import { Loader } from '@react-three/drei'
import { TextGeometry  }  from 'three/examples/jsm/geometries/TextGeometry'
import './styles.css'
import AlexDemos from './AlexDemos';
extend({ TextGeometry })
var gradient = 'linear-gradient(#e66465, #8f97e6)';
let move = false;
const groundMaterial = 'ground'
const slipperyMaterial = {
  friction: 0,
  name: 'slippery',
}
const container = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(#e66465, #8f97e6)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'opacity 300ms ease',
  zIndex: 1000,
}
const inner = {
  width: 200,
  height: 10,
  background: '#272727',
  textAlign: 'center',
}
const bar = {
    height: 10,
    width: '100%',
    background: 'green',
    transition: 'transform 200ms',
    transformOrigin: 'left center',
  }
const data = {
  display: 'inline-block',
  position: 'center',
  fontVariantNumeric: 'tabular-nums',
  marginTop: '0.8em',
  color: '#f0f0f0',
  fontSize: '0.8em',
  fontFamily: `-apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Helvetica Neue", Helvetica, Arial, Roboto, Ubuntu, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  whiteSpace: 'nowrap',
}
function Plane(props) {
  useContactMaterial(groundMaterial, groundMaterial, {
    friction: 0.4,
    restitution: 0.3,
    contactEquationStiffness: 1e8,
    contactEquationRelaxation: 3,
    frictionEquationStiffness: 1e8,
    frictionEquationRegularizationTime: 3
  });
  useContactMaterial(groundMaterial, slipperyMaterial, {
    friction: 0,
    restitution: 0,
  });
  const [ref] = usePlane(() => ({args:[500,500], rotation: [-Math.PI / 2 , 0, 0],material:groundMaterial, ...props }))
  return (
    <mesh ref={ref} receiveShadow>
      <planeGeometry args={[500, 500]} />
      {/* <meshBasicMaterial attach="material" color='red' /> */}
      <shadowMaterial color="#171717" transparent opacity={0.4} />
    </mesh>
  )
}



function Cube(props) {  
  const [ref1, api] = useBox(() => ({args: [1,1,1],mass: 1,position: [5, 0.5, 0],material:slipperyMaterial
}))
  const texture_1 = useLoader(TextureLoader, '/snip.PNG')
  return (
    <mesh red = {ref1} receiveShadow castShadow {...props}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial map={texture_1} attach="material" />
    </mesh>
  )
}
function PictureCube(props) {
  const texture_1 = useLoader(TextureLoader, 'snip.PNG')
  const mesh = useRef()
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    mesh.current.rotation.set(0.1 + Math.cos(t / 4.5) / 10, Math.sin(t / 4) / 4, 0.3 - (1 + Math.sin(t / 4)) / 8)
    mesh.current.position.y = (1 + Math.sin(t / 8))
  })
  return (
    <mesh
      receiveShadow castShadow
      {...props}
      ref={mesh}
      scale={active ? 2 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial map={texture_1} attach="material" />
    </mesh>  )}
const Lights = () => {
  var side = 20;
  var size = 512;
  return (
    <>
      <ambientLight intensity={0.1} />
      <directionalLight
        intensity={1}
        castShadow
        position={[10, 10, 10]}
        shadow-mapSize-width={size}
        shadow-mapSize-height={size}
        // shadow-camera-far = {500}
        shadow-camera-left={-side*2}
        shadow-camera-right={side*2}
        shadow-camera-top={side}
        shadow-camera-bottom={-side}
      />
    </>
  );
};
function SpinBox() {
  const [ref, api] = useBox(() => ({ args: [1, 1, 1], mass: 1, type: 'Kinematic' }))
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    api.position.set(Math.sin(t * 2) * 5, Math.cos(t * 2) * 5, 3)
    api.rotation.set(Math.sin(t * 6), Math.cos(t * 6), 0)
  })
  return (
    <mesh ref={ref} castShadow receiveShadow>
      <boxBufferGeometry args={[1, 1, 1]} />
      <meshLambertMaterial color="white" />
    </mesh>
  )
}
ReactDOM.render(
  <>
  <Suspense fallback={null}>
  <Canvas shadows shadowMap dpr={[1, 2]}>
    <Lights />
    <Physics stepSize={1/120} tolerance = {0.001} size = {1000} maxSubSteps = {3} quatNormalizeFast = {false} solver = {'GS'} allowSleep={true}>
      {/* <Debug color="black" scale={1}> */}
          <Plane position={[0, 0, 0]} />
          <AlexDemos />
      {/* </Debug> */}
    </Physics>
  </Canvas>
  </Suspense>
  <Loader containerStyles={container} innerStyles={inner} barStyles = {bar} dataStyles={data}/>
  </>
  ,
  document.getElementById('root')
)
