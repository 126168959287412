/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF,useTexture } from '@react-three/drei'
import { useFrame} from '@react-three/fiber'

export default function Laptop({ ...props }) {
  var direction = 0.008;
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/laptop.glb')
  // const [screenImage] = useTexture(['/assets/pictures/screen.png', ])
  const [screenImage] = useTexture(['/assets/pictures/wall.jpg', ])
  useFrame((state) => {
      group.current.rotation.x += direction;
    if(group.current.rotation.x >= 1.94 || group.current.rotation.x <= 0){
      direction = direction*-1;
    }
  })
  return (
    <group {...props} dispose={null}>
        <group position={[15, 2.1, -15.5]} scale={0.05}>
          <mesh geometry={nodes.lower001.geometry} material={materials['IlNnjEDxsExlBOr.006']} />
          <mesh geometry={nodes.lower002.geometry} material={materials['iPZiEncThgnBMJv.006']} />
          <mesh geometry={nodes.lower003.geometry} material={materials['LJSCtLIrHNHZnIH.006']} />
          <mesh geometry={nodes.lower004.geometry} material={materials['hPcehRUjcLAosED.006']} />
          <mesh geometry={nodes.lower005.geometry} material={materials['BMKLbAPYqPmfArt.006']} />
          <mesh geometry={nodes.lower006.geometry} material={materials['SKOFticEGTqECbB.006']} />
          <mesh geometry={nodes.lower007.geometry} material={materials['initialShadingGroup.006']} />
          <mesh geometry={nodes.lower008.geometry} material={materials['pZbDFXVUkfRwjmQ.006']} />
          <mesh geometry={nodes.lower009.geometry} material={materials['SKOFticEGTqECbB.006']} />
          <mesh geometry={nodes.lower010.geometry} material={materials['hPcehRUjcLAosED.006']} />
          <mesh geometry={nodes.lower011.geometry} material={materials['NQXltfOcKPZPQdI.006']} />
          <mesh geometry={nodes.lower012.geometry} material={materials['lbGSuJJwEsUTzIm.006']} />
          <mesh geometry={nodes.lower013.geometry} material={materials['zWLcvvnJhbcTEtJ.006']} />
          <mesh geometry={nodes.lower014.geometry} material={materials['PCfVLhSpFVCvRmc.006']} />
          <mesh geometry={nodes.lower015.geometry} material={materials['zWLcvvnJhbcTEtJ.006']} />
          <mesh geometry={nodes.lower016.geometry} material={materials['BMKLbAPYqPmfArt.006']} />
          <mesh geometry={nodes.lower017.geometry} material={materials['lbGSuJJwEsUTzIm.006']} />
          <group  ref={group} >
          <mesh geometry={nodes.upper001.geometry} material={materials['UpOvKwLUUXPmnPU.006']} >
          <meshStandardMaterial map={screenImage} attach="material" />
        </mesh>
          <mesh geometry={nodes.upper002.geometry} material={materials['VfcxYmbOfnqunYx.006']} />
          <mesh geometry={nodes.upper003.geometry} material={materials['mpJhsaJJZPPWMEX.006']} />
          <mesh geometry={nodes.upper004.geometry} material={materials['zqeFZcIteZtOShc.006']} />
          <mesh geometry={nodes.upper005.geometry} material={materials['lbGSuJJwEsUTzIm.006']} />
          <mesh geometry={nodes.upper006.geometry} material={materials['LJSCtLIrHNHZnIH.006']} />
          <mesh geometry={nodes.upper007.geometry} material={materials['yVmFXNTCIwNkqVT.006']} />
          <mesh geometry={nodes.upper008.geometry} material={materials['SELuppcPLrlTpBi.006']} />
          </group>
        </group>
    </group>
  )
}

useGLTF.preload('/laptop.glb')
