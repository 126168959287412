import React, { useRef, useEffect } from 'react'
import { useFrame, useThree, useLoader } from '@react-three/fiber'
import { useFBX } from '@react-three/drei'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Physics, usePlane, useBox, useCylinder, useSphere } from '@react-three/cannon'
import Assets from './Assets';
import * as THREE from 'three';
import Laptop from './Laptop';
import nipplejs from "nipplejs";
import { wait } from '@testing-library/user-event/dist/utils'
import Simon from './Simon';
var posX = 0;
var posY = 1.5;
var posZ = 5;
let AlexCamera = true;
let PhoneCamera = false;
let LaptopCamera = false;
let moving = false;
let dancing = false;
// vars
let fwdValue = 0;
let bkdValue = 0;
let rgtValue = 0;
let lftValue = 0;
let joyManager;
let movementInputed = false;
function addJoystick() {
  const options = {
    zone: document.getElementById('joystick'),
    color:'green',
    size: 120,
    multitouch: false,
    maxNumberOfNipples: 1,
    mode: 'static',
    restJoystick: true,
    shape: 'circle',
    // position: { top: 20, left: 20 },
    position: { top: '60px', left: '60px' },
    dynamicPage: true,
  }

  joyManager = nipplejs.create(options);

  joyManager['0'].on('move', function (evt, data) {
    const forward = data.vector.y
    const turn = data.vector.x

    if (forward > 0) {
      fwdValue = Math.abs(forward)
      bkdValue = 0
    } else if (forward < 0) {
      fwdValue = 0
      bkdValue = Math.abs(forward)
    }

    if (turn > 0) {
      lftValue = 0
      rgtValue = Math.abs(turn)
    } else if (turn < 0) {
      lftValue = Math.abs(turn)
      rgtValue = 0
    }
  })

  joyManager['0'].on('end', function (evt) {
    bkdValue = 0
    fwdValue = 0
    lftValue = 0
    rgtValue = 0
  })

}


addJoystick();
function LaptopDemo(props) {
  var positionX = 15;
  var positionY = 3;
  var positionZ = -15;
  const vec = new THREE.Vector3(positionX, 4, -13);
  const [ref, api] = useCylinder(() => ({ args: [1, 1, 2, 32], mass: 1, position: [positionX, 1, positionZ], rotation: [0, 0, 0], ...props, type: 'Static' }))
  const { camera } = useThree();
  useFrame(() => {
    if (LaptopCamera) {
      camera.position.lerp(vec, 0.1);
    }
  })
  return (
    <>
      <mesh onClick={() => {
        LaptopCamera = !LaptopCamera;
        AlexCamera = !AlexCamera;
      }}
        onPointerOver={(event) => document.body.style.cursor = 'pointer'}
        onPointerOut={(event) => document.body.style.cursor = 'auto'}
        ref={ref} receiveShadow castShadow>
        <cylinderBufferGeometry attach="geometry" args={[1, 1, 2, 32]} />
        <meshNormalMaterial attach="material" />
      </mesh>
    </>
  )
} function PhoneDemo(props) {
  const gltf = useLoader(GLTFLoader, '/assets/phone.glb')
  gltf.scene.position.x = 20;
  gltf.scene.position.y = 3;
  gltf.scene.position.z = -15;
  gltf.scene.scale.set(0.1, 0.1, 0.1);
  const vec = new THREE.Vector3(20, 4, -13);
  const [ref, api] = useCylinder(() => ({ args: [1, 1, 2, 32], mass: 1, position: [20, 1, -15], rotation: [0, 0, 0], ...props, type: 'Static' }))
  const { camera } = useThree();
  useFrame(() => {
    if (PhoneCamera) {
      camera.position.lerp(vec, 0.1);
    }
  })
  useFrame((state) => {
    gltf.scene.rotation.y += 0.02;
  })
  return (
    <>
      <mesh position={[20, 3.5, -15]} onClick={() => {
        PhoneCamera = !PhoneCamera;
        AlexCamera = !AlexCamera;
      }}
        onPointerOver={(event) => document.body.style.cursor = 'pointer'}
        onPointerOut={(event) => document.body.style.cursor = 'auto'}>
        <boxGeometry args={[0.4, 1, 0.3]} />
        <meshPhongMaterial color="#ff0000" opacity={0} transparent />
      </mesh>
      <mesh onClick={() => {
        PhoneCamera = !PhoneCamera;
        AlexCamera = !AlexCamera;
      }}
        onPointerOver={(event) => document.body.style.cursor = 'pointer'}
        onPointerOut={(event) => document.body.style.cursor = 'auto'}
        receiveShadow castShadow ref={ref}>
        <cylinderBufferGeometry attach="geometry" args={[1, 1, 2, 32]} />
        <meshNormalMaterial attach="material" />
      </mesh>
      <primitive object={gltf.scene} />
    </>
  )
}
function Camera(props) {
  const vec = new THREE.Vector3(posX, 7, posZ);
  const { camera } = useThree();
  camera.position.set(0, 7, 25);
  camera.rotation.x = (-Math.PI / 6);
  useFrame((state) => {
    if (AlexCamera) {
      PhoneCamera = false;
      LaptopCamera = false;
      vec.x = posX;
      vec.z = posZ + 7;
      camera.position.lerp(vec, 0.5);
    }
  })
  return <perspectiveCamera ref={camera} {...props} />
}
const slipperyMaterial = {
  friction: 0,
  name: 'slippery',
}
function Model(props) {
  const [ref1, api] = useCylinder(() => ({
    args: [0.5, 0.8, 2*posY, 32],
    mass: 4,
    fixedRotation: true,
    position: [0, posY, 0],
    material:slipperyMaterial,
    sleepSpeedLimit: 0
  }))
  // const state = useRef({
  //   vel: [0, 0, 0]
  // });
  // useEffect(() => {
  //   api.velocity.subscribe((v) => (state.current.vel = v));
  // }, [api]);
  useEffect(() => api.position.subscribe(v => pos.current = v), [])
  var idle, dance, run, walk;
  const pos = useRef([0, 0, 0])
  var left = false;
  var right = false;
  var up = false;
  var down = false;
  const fbx = useFBX('/assets/Alex.fbx')
  fbx.scale.set(.02, .02, .02);
  //set offset of model for usebox
  fbx.position.y = -posY;
  const anim = new FBXLoader();
  var mixer = new THREE.AnimationMixer(fbx);
  anim.load('/anim/dance2.fbx', (anim) => {
    dance = mixer.clipAction(anim.animations[0]);
  });
  anim.load('/anim/idle.fbx', (anim) => {
    idle = mixer.clipAction(anim.animations[0]);
    idle.play();
  });
  anim.load('/anim/run.fbx', (anim) => {
    run = mixer.clipAction(anim.animations[0]);
  });
  anim.load('/anim/walk.fbx', (anim) => {
    walk = mixer.clipAction(anim.animations[0]);
  });
  function startRun() {
    if(!moving){
      dance.stop();
      idle.stop();
      run.play();
      moving = true;
      dancing = false;
    }
  }  
  function startIdle() {
    if(!dancing){
    run.stop();
    idle.play();
    }
  }    
  function AlexClick() {
    if(!dancing){
      idle.stop(); 
      dance.play();
      dancing = true;
    }
  }  
  const handleKeyDown = (e) => {
    if (e.keyCode === 65) {
      left = true;
    }
    else if (e.keyCode === 87) {
      up = true;
    }
    else if (e.keyCode === 83) {
      down = true;
    }
    else if (e.keyCode === 68) {
      right = true;
    }
    if (left || right || up || down) {
      startRun();
      movementInputed = true;
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const handleKeyUp = (e) => {
    if (e.keyCode === 65) {
      left = false;
    }
    else if (e.keyCode === 87) {
      up = false;
    }
    else if (e.keyCode === 83) {
      down = false;
    }
    else if (e.keyCode === 68) {
      right = false;
    }
    if (!left && !right && !up && !down) {
      startIdle();
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
  var speed = 10;
  var diag = speed*0.7;
  var xVel = 0;
  var zVel = 0;
  useFrame(() => {
    xVel = 0;
    zVel = 0;
    if (left) {
      startRun();
      AlexCamera = true;
      if (up) {
        xVel += -diag;
        zVel += -diag;
        api.rotation.set(0, -3 * Math.PI / 4, 0);
      }
      else if (down) {
        xVel += -diag;
        zVel += diag;
        api.rotation.set(0, -Math.PI / 4, 0);
      }
      else {
        xVel += -speed;
        api.rotation.set(0, -Math.PI / 2, 0);
      }
    }
    else if (right) {
      startRun();
      AlexCamera = true;
      if (up) {
        xVel += diag;
        zVel += -diag;
        api.rotation.set(0, 3 * Math.PI / 4, 0);
      }
      else if (down) {
        xVel += diag;
        zVel += diag;
        api.rotation.set(0, Math.PI / 4, 0);
      }
      else {
        xVel += speed;
        api.rotation.set(0, Math.PI / 2, 0);
      }
    }
    else if (up) {
      startRun();
      AlexCamera = true;
      zVel += -speed;
      api.rotation.set(0, Math.PI, 0);
    }
    else if (down) {
      startRun();
      AlexCamera = true;
      zVel += speed;
      api.rotation.set(0, 0, 0);
    }
    else {
      if(movementInputed){
        if(fwdValue == 0 && bkdValue == 0 && lftValue == 0 && rgtValue == 0){
          startIdle();
          moving = false;
                }
      }
    }
    if (lftValue > 0.5) {
      startRun();
      AlexCamera = true;
      movementInputed = true;
      if (fwdValue > 0.5) {
        xVel += -diag;
        zVel += -diag;
        api.rotation.set(0, -3 * Math.PI / 4, 0);
      }
      else if (bkdValue > 0.5) {
        xVel += -diag;
        zVel += diag;
        api.rotation.set(0, -Math.PI / 4, 0);
      }
      else {
        xVel += -speed;
        api.rotation.set(0, -Math.PI / 2, 0);
      }
    }
    else if (rgtValue > 0.5) {
      startRun();
      AlexCamera = true;
      movementInputed = true;
      if (fwdValue > 0.5) {
        xVel += diag;
        zVel += -diag;
        api.rotation.set(0, 3 * Math.PI / 4, 0);
      }
      else if (bkdValue > 0.5) {
        xVel += diag;
        zVel += diag;
        api.rotation.set(0, Math.PI / 4, 0);
      }
      else {
        xVel += speed;
        api.rotation.set(0, Math.PI / 2, 0);
      }
    }
    else if (fwdValue > 0.5) {
      movementInputed = true;
      startRun();
      AlexCamera = true;
      zVel += -speed;
      api.rotation.set(0, Math.PI, 0);
    }
    else if (bkdValue > 0.5) {
      movementInputed = true;
      startRun();
      AlexCamera = true;
      zVel += speed;
      api.rotation.set(0, 0, 0);
    }
    api.velocity.set(xVel, 0, zVel);
    posX = pos.current.at(0);
    posZ = pos.current.at(2);
    //console.log("xvel = " + xVel + " acutal - " + state.current.vel[0]);
  });

  useFrame((state, delta) => {
    mixer?.update(delta)
  })
  //traverse through meshes and apply castshadow true
  fbx.traverse(function (child) {
    if (child instanceof THREE.Mesh) {
      child.castShadow = true;
    }
    // if (child.isMesh) {
    //   child.position.set(0, -10, 0);
    // }
  });




return (
  <group ref={ref1}>
  <primitive onClick={() => { AlexClick(); }} object={fbx} />
  </group>
)}

function AlexDemos() {

  return (
    <>
      <Model />
      <Laptop />
      <Assets />
      <LaptopDemo />
      <PhoneDemo />
      <Camera />
      <Simon/>
    </>

  )


}

export default AlexDemos;
