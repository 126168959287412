import React from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { TextureLoader } from 'three/src/loaders/TextureLoader.js'
import { useBox, useCylinder } from '@react-three/cannon'
import { useLoader, useFrame, useThree } from '@react-three/fiber'
import { RoundedBox, useFBX } from '@react-three/drei'
import * as THREE from 'three';
import JSONfont from "./Roboto.json";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'

const start = -5;
let move = false;
const height = 6;
function WorkExperience() {
  const gltf = useLoader(GLTFLoader, '/assets/sign.glb')
  gltf.scene.position.x = 5;
  gltf.scene.position.y = height + 0.5;
  gltf.scene.position.z = 0;
  // useFrame((state) => {
  //   const t = state.clock.getElapsedTime()
  //   gltf.scene.rotation.set(Math.cos(t/4)/4 , 0, -Math.cos(t)/4)
  //   gltf.scene.position.y = 6 + Math.sin(t / 4);
  // })
  return (
    <primitive object={gltf.scene} />
  )
}

function Demos() {
  const gltf = useLoader(GLTFLoader, '/assets/demos.glb')
  gltf.scene.position.x = 0;
  gltf.scene.position.y = height;
  gltf.scene.position.z = 0;
  // useFrame((state) => {
  //   const t = state.clock.getElapsedTime()
  //   gltf.scene.rotation.set(Math.cos(t)/4 , 0, Math.cos(t)/4 )
  //   gltf.scene.position.y = 6 + Math.sin(t / 4);
  // })
  return (
    <primitive object={gltf.scene} />
  )
}
function Links() {
  const gltf = useLoader(GLTFLoader, '/assets/links.glb')
  gltf.scene.position.x = -5;
  gltf.scene.position.y = height;
  gltf.scene.position.z = 0;
  // useFrame((state) => {
  //   const t = state.clock.getElapsedTime()
  //   gltf.scene.rotation.set(Math.cos(t/2)/4 , 0, -Math.cos(t)/4)
  //   gltf.scene.position.y = 6 + Math.sin(t / 4);
  // })
  return (
    <primitive object={gltf.scene} />
  )
}
function LinkBox(props) {
  const frame = useLoader(TextureLoader, '/assets/pictures/frame.png')
  const frame2 = useLoader(TextureLoader, '/assets/pictures/frame2.png')
  const [hovered, setHover] = React.useState(false)
  React.useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])
  return (
    <mesh {...props} rotation={[-Math.PI / 2, 0, 45]}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
      onClick={(event) => window.open(props.link)} >
      <planeGeometry args={[3, 3]} />
      {/* <meshBasicMaterial attach="material" color='red' /> */}
      <meshStandardMaterial map={hovered ? frame2 : frame} attach="material" transparent />
    </mesh>
  )
}
function Linkedin() {
  var x = -20;
  var y = 1.5;
  var z = -5;
  const gltf = useLoader(GLTFLoader, '/assets/linkedin.glb')
  const [ref, api] = useBox(() => ({ args: [3, 3, 1.5], mass: 5, position: [x, y, z], rotation: [0, 45, 0], type: 'Dynamic' }))
  gltf.scene.scale.set(1.5, 1.5, 1.5);
  gltf.scene.position.x = x;
  gltf.scene.position.y = y;
  gltf.scene.position.z = z;
  gltf.scene.children.forEach((mesh, i) => {
    mesh.castShadow = true;
  })
  return (
    <>
      <primitive ref={ref} castShadow object={gltf.scene} />
      <LinkBox position={[x + 2.5, 0.1, z + 1.75]} link="https://www.linkedin.com/in/alex-molina/" />
    </>
  )
}
function Gmail(obj) {
  var x = -22.5;
  var y = 1.5;
  var z = 0;
  const gltf = useLoader(GLTFLoader, '/assets/gmail.glb')
  const [ref, api] = useBox(() => ({ args: [3, 3, 1.5], mass: 5, position: [x, y, z], rotation: [0, 45, 0], type: 'Dynamic' }))
  gltf.scene.scale.set(1.5, 1.5, 1.5);
  gltf.scene.position.x = x;
  gltf.scene.position.y = y;
  gltf.scene.position.z = z;
  gltf.scene.children.forEach((mesh, i) => {
    mesh.castShadow = true;
  })
  return (
    <>
      <primitive ref={ref} object={gltf.scene} />
      <LinkBox position={[x + 2.5, 0.1, z + 1.75]} link='mailto:aom2578@gmail.com' />
    </>
  )
}
function Text(props) {
  const location = "/assets/pictures/" + props.name + ".png";
  const texture = useLoader(TextureLoader, location);
  return (
    <mesh {...props} rotation={[-Math.PI / 4, 0, 0]}  >
      <planeGeometry args={[5, 5]} />
      <meshStandardMaterial map={texture} attach="material" transparent />
    </mesh>
  )
}
function TextFloor(props) {
  const location = "/assets/pictures/" + props.name + ".png";
  const texture = useLoader(TextureLoader, location);
  return (
    <mesh {...props} rotation={[-Math.PI / 2, 0, 0]}  >
      <planeGeometry args={[4, 4]} />
      <meshStandardMaterial map={texture} attach="material" transparent />
    </mesh>
  )
}
function Brick(props) {
  const [ref] = useBox(() => ({ args: [1, 0.5, 0.5], mass: 1, rotation: [0, 0, 0], ...props, type: 'Dyanimc' }));
  return (
    <mesh ref={ref} castShadow receiveShadow>
      <RoundedBox args={[1, 0.5, 0.5]} radius={0.1} smoothness={4} position={[0, 0, 0]}>
        <meshLambertMaterial attach="material" color={"grey"} />
      </RoundedBox>
    </mesh>
  )
}
function Wall() {
  const startX = -20;
  const startY = -15;
  return (
    <>
      <Brick position={[startX, 0.25, startY]} />
      <Brick position={[startX, 0.75, startY]} />

      <Brick position={[startX + 1, 0.25, startY]} />
      <Brick position={[startX + 1, 0.75, startY]} />
      <Brick position={[startX + 1, 1.25, startY]} />

      <Brick position={[startX + 2, 0.25, startY]} />
      <Brick position={[startX + 2, 0.75, startY]} />
      <Brick position={[startX + 2, 1.25, startY]} />
      <Brick position={[startX + 2, 1.75, startY]} />

      <Brick position={[startX + 3, 0.25, startY]} />
      <Brick position={[startX + 3, 0.75, startY]} />
      <Brick position={[startX + 3, 1.25, startY]} />
      <Brick position={[startX + 3, 1.75, startY]} />
      <Brick position={[startX + 3, 2.25, startY]} />

      <Brick position={[startX + 4, 0.25, startY]} />
      <Brick position={[startX + 4, 0.75, startY]} />
      <Brick position={[startX + 4, 1.25, startY]} />
      <Brick position={[startX + 4, 1.75, startY]} />
      <Brick position={[startX + 4, 2.25, startY]} />
      <Brick position={[startX + 4, 2.75, startY]} />
      
      <Brick position={[startX + 5, 0.25, startY]} />
      <Brick position={[startX + 5, 0.75, startY]} />
      <Brick position={[startX + 5, 1.25, startY]} />
      <Brick position={[startX + 5, 1.75, startY]} />
      <Brick position={[startX + 5, 2.25, startY]} />

      <Brick position={[startX + 6, 0.25, startY]} />
      <Brick position={[startX + 6, 0.75, startY]} />
      <Brick position={[startX + 6, 1.75, startY]} />
      <Brick position={[startX + 6, 1.25, startY]} />

      <Brick position={[startX + 7, 0.25, startY]} />
      <Brick position={[startX + 7, 0.75, startY]} />
      <Brick position={[startX + 7, 1.25, startY]} />
      
      <Brick position={[startX + 8, 0.25, startY]} />
      <Brick position={[startX + 8, 0.75, startY]} />
    </>
  )
}
function WASD() {
  const fbx = useFBX('/assets/keys.fbx');
  fbx.scale.set(.0005, .0005, .0005);
  fbx.position.x = 0;
  fbx.position.y = 0;
  fbx.position.z = 4;
  return (
    // <group ref={ref1}>
    <primitive object={fbx} />
    // </group>
  )
} function Frame() {
  const gltf = useLoader(GLTFLoader, '/assets/frame.glb')
  gltf.scene.position.x = 4;
  gltf.scene.position.y = 2;
  gltf.scene.position.z = 0;
  // gltf.scene.scale(2,2,2);
  return (
    <primitive scale={2} object={gltf.scene} />
  )
}
function TextMesh(props) {
  // load in font
  const font = new FontLoader().parse(JSONfont);
  const [ref, api] = useBox(() => ({ args: [1, 1, 0.3], mass: 2, position: props.position, rotation: [0, 0, 0], type: 'Dynamic' }));
  const colors = ["green", "red", "blue", "pink", "purple", "gray", "white"];
  let ran = Math.floor(Math.random() * 6);
  let x = -0.5;
  let y = -0.5;
  let z = -0.1;
  if (props.letter === 'L') {
    x = -0.35;
  }
  if (props.letter === 'I') {
    x = -0.25;
  }
  if (props.letter === 'M') {
    x = -0.6;
  }
  if (props.letter === 'O') {
    x = -0.45;
  }
  return (
    <group ref={ref}>
      <mesh position={[x, y, z]}   >
        <textGeometry args={[props.letter, { font, size: 1, height: 0.2 }]} />
        <meshPhysicalMaterial attach="material" color={'lime'}/> //color={colors[ran]} />
      </mesh>
    </group>
  );
}
function Assets(props) {
  return (
    <>
      <WorkExperience />
      <Demos />
      <Links />
      <Linkedin />
      <Gmail />
      <Text position={[15, 0.1, 0]} name="work" />
      <Text position={[20, 0.1, 0]} name="websitedesc" />
      <Text position={[0, 2.5, -20]} name="simon" />
      <TextFloor position={[-5, -0.01, -2]} name="keys" />
      <TextFloor position={[5, -0.01, -2]} name="click" />
      <Wall />
      <TextMesh position={[start, 0.5, 2]} letter='A' />
      <TextMesh position={[start + 1, 0.5, 2]} letter='L' />
      <TextMesh position={[start + 2, 0.5, 2]} letter='E' />
      <TextMesh position={[start + 3, 0.5, 2]} letter='X' />
      <TextMesh position={[start + 5, 0.5, 2]} letter='M' />
      <TextMesh position={[start + 6, 0.5, 2]} letter='O' />
      <TextMesh position={[start + 7, 0.5, 2]} letter='L' />
      <TextMesh position={[start + 8, 0.5, 2]} letter='I' />
      <TextMesh position={[start + 9, 0.5, 2]} letter='N' />
      <TextMesh position={[start + 10, 0.5, 2]} letter='A' />

      {/* <TextMesh position={[start, 0.5, 10]} letter='L' />
      <TextMesh position={[start + 1, 0.5, 10]} letter='A' />
      <TextMesh position={[start + 2, 0.5, 10]} letter='N' />
      <TextMesh position={[start + 3, 0.5, 10]} letter='G' />
      <TextMesh position={[start + 4, 0.5, 10]} letter='U' />
      <TextMesh position={[start + 5, 0.5, 10]} letter='A' />
      <TextMesh position={[start + 6, 0.5, 10]} letter='G' />
      <TextMesh position={[start + 7, 0.5, 10]} letter='E' />
      <TextMesh position={[start + 8, 0.5, 10]} letter='S' /> */}

      {/* <TextMesh position={[start + 8, 0.5, 4]} letter='C' />
      <TextMesh position={[start + 9, 0.5, 4]} letter='+' />
      <TextMesh position={[start + 10, 0.5, 4]} letter='+' /> */}
      {/* <Frame/> */}
    </>
  );
}

export default Assets;